:root {
  /*--less-white: #1d2d44;*/
  --less-white: rgb(255, 255, 255);
  --theme-color: #b31a27;
  --theme-color-dark: #751119;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.all-container {
  margin-top: 8vh;
}

.about-us p {
  overflow-y: scroll;
  margin-block-start: 0;
  margin-block-end: 0;
}

section {
  width: 100%;
  min-height: 500px;
  height: auto;
  padding: 50px 150px;
  display: flex;
  flex-direction: column;
}

.Research .about-us {
  padding: 0;
}

.Auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92vh;
  background-color: #ddd;
}

.Auth .title {
  font-size: 20px;
  font-weight: bold;
}

.Auth form {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px;
  margin: 20px;
  text-align: center;
}

.Auth form input {
  margin-top: 30px;
}

.Auth form button {
  margin-top: 30px;
}

h1 {
  margin-block-start: 0;
  margin-block-end: 0;
}

h2 {
  padding: 80px;
  text-align: center;
}

button {
  font-family: "Montserrat", sans-serif;
  background-color: var(--less-white);
  border-radius: 30px;
  border: 2px solid var(--theme-color);
  color: var(--theme-color);
  height: 40px;
  margin: 5px;
  width: 200px;
  transition: background-color 0.5s, color 0.2s;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

button:hover {
  color: var(--less-white);
  background-color: var(--theme-color);
}

.Auth button {
  width: 50%;
}

body,
html {
  font-family: "Roboto", sans-serif;
  font-size: 0.97em;
  max-width: 100%;
  overflow-x: hidden;
}

#industries {
  display: grid;
  align-self: center;
  width: 100%;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "one two three four";
  justify-content: start;
  align-items: flex-start;
  overflow-x: auto;
}

body {
  position: relative;
}

span {
  font-weight: bold;
  text-transform: uppercase;
}

.Transcription form {
  
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  padding: 30px 70px;
  border-radius: 20px;
  max-width: 50%;
  margin: 3em auto;
}

.Transcription form .inputs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 2s ease-in;
  max-height: 500px;
}

.Transcription form .inputs.collapsed{
  max-height: 0;
}

.paypal-container{
  overflow: hidden;
  transition: all 2s ease-in;
  display: flex;
  justify-content: center;
}

.paypal-container.collapsed{
  max-height: 0;
}


@media screen and (max-width: 768px) {
  .Transcription form {
    padding: 20px;
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {
  section {
    min-height: 800px;
  }

  form {
    width: 95%;
    padding: 30px;
  }
  form > div {
    flex-direction: column;
  }

  form > div > label {
    width: 100%;
    display: none;
  }

  #industries {
    grid-gap: 20px;
    grid-template-columns: none;
    grid-template-areas:
      "one"
      "two"
      "three"
      "four";
  }

  .Auth form {
    width: 90%;
  }
}

form > div {
  display: flex;
  margin: 10px;
  justify-content: space-between;
  width: 100%;
}

form label {
  font-size: 14px;
  display: inline-block;
  width: 20%;
  text-align: left;
  display: none;
}

form input {
  padding: 12px;
  margin: 0.5em auto;
  height: 50px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

form input :focus {
  outline: none;
  border: 2px solid var(--theme-color);
}

form input:focus::placeholder {
  color: transparent;
  transition: all 0.3s ease;
}
.progress-bar {
  position: relative;
  height: 20px;
  width: 50%;
  border-radius: 50px;
  border: 1px solid #eee;
}

.filler {
  background: #1da598;
  /* background: var(--theme-color); */
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.modalHeader {
  padding: 30px;
  padding-top: 10px;
}

table {
  margin: 50px;
}

th,
td {
  padding: 20px;
  margin: 20px;
  border-bottom: 1px solid #ddd;
}

th {
  color: white;
  background-color: var(--theme-color);
}

tr:nth-child(even) {
  background-color: #fff;
}
tr:nth-child(odd) {
  background-color: #f5f5f5;
}

form h2 {
  padding: 0px;
  width: 100%;
  margin: 20px;
  font-family: "Rufina", serif;
}

h3 {
  margin-bottom: 20px;
  align-self: flex-start;
  font-family: "Rufina", serif;
  display: none;
}

.transcription-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
